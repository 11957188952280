$(document).ready(function() {

	$('.js-toggle').on('click', function(e) {

		const elem = $(e.currentTarget)
		const parent = elem.parents(elem.data('parent'))
		const className = elem.data('class')

		parent.toggleClass(className)
	})

  // tooltips on hover
  $('[data-toggle=\'tooltip\']').tooltip({container: 'body', trigger: 'hover'});

  // Makes tooltips work on ajax generated content
  $(document).ajaxStop(function() {
    $('[data-toggle=\'tooltip\']').tooltip({container: 'body'});
  });
})

// Функция добавления toast на страницу
var addToastToDocument = function(head, body, delay = 5000) {

	if(!head && !body) return

	// сформируем toast html
	var html = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="true" data-delay="' + delay + '">';
  html += '  <div class="toast-header">';
  if(head)
    html += '    <strong class="mr-auto">' + head + '</strong>';
  else
  	html += '    <strong class="mr-auto">Товар добавлен</strong>';
  html += '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">';
  html += '      <span aria-hidden="true">&times;</span>';
  html += '    </button>';
  html += '  </div>';
  if(body)
    html += '  <div class="toast-body">' + body + '</div>';
  html += ' </div>';

	// Добавим в DOM
  $('.toast-outer').append(html)

  // найдем его ссылку
  var lastToast = $('.toast-outer').find('.toast').last()

  // покажем toast
  lastToast.toast('show')

  // после скрытия - удалим из DOM
  lastToast.on('hidden.bs.toast', function () {
    $(this).remove()
  })
}

function getURLVar(key) {
  var value = [];

  var query = String(document.location).split('?');

  if (query[1]) {
    var part = query[1].split('&');

    for (i = 0; i < part.length; i++) {
      var data = part[i].split('=');

      if (data[0] && data[1]) {
        value[data[0]] = data[1];
      }
    }

    if (value[key]) {
      return value[key];
    } else {
      return '';
    }
  } else {       // Изменения для seo_url от Русской сборки OpenCart 3x
    var query = String(document.location.pathname).split('/');
    if (query[query.length - 1] == 'cart') value['route'] = 'checkout/cart';
    if (query[query.length - 1] == 'checkout') value['route'] = 'checkout/checkout';
    
    if (value[key]) {
      return value[key];
    } else {
      return '';
    }
  }
}
